import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  LoadingOverlay,
} from "@mantine/core";
import { useMutation } from "react-query";
import { createOrder } from "../../endpoints/orders";
import { API_ROUTES } from "../../utils/apiRoutes";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../store/features/user";
import { notifications } from "@mantine/notifications";
import { RiErrorWarningLine } from "react-icons/ri";

export const CourseCard = ({ thumbnail, description, price, name, id }) => {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const buyCourseMutation = useMutation({
    mutationFn: createOrder,
    mutationKey: [API_ROUTES.PAYMENT.CREATE],
    onSuccess: (response) => {
      if (response.data.paymentUrl) {
        window.open(response.data.paymentUrl);
      }
    },
    onError: (err) => {
      navigate("/login");
      notifications.show({
        message: err?.response?.data?.message,
        icon: <RiErrorWarningLine />,
        title: "Error",
      });
    },
  });

  const onBuyCourse = () => {
    buyCourseMutation.mutate(id);
  };

  const onViewCourse = () => {
    const courseData = user?.courses.find((item) => item?.course?._id === id);
    dispatch(updateUser({ selectedCourse: courseData.course }));
    navigate("/viewCourse");
  };

  const checkCourse = (id) => {
    if (!user.courses) {
      return -1;
    }
    const index = user?.courses.findIndex((item) => item?.course?._id === id);
    return index;
  };

  return (
    <>
      <LoadingOverlay
        visible={buyCourseMutation.isLoading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 1 }}
      />
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        w={300}
        className="relative"
      >
        <Card.Section>
          <Image src={thumbnail} height={160} alt="course" />
        </Card.Section>

        <Group justify="space-between" mt="md" mb="xs">
          <Text fw={500}> {name}</Text>

          {checkCourse(id) > -1 ? (
            <Button color="blue" radius="md" onClick={onViewCourse}>
              View
            </Button>
          ) : (
            <Button color="blue" radius="md" onClick={onBuyCourse}>
              Buy
            </Button>
          )}
        </Group>

        <Badge>₹ {price}</Badge>
        <Text size="sm" c="dimmed" className="max-h-[100px] overflow-auto">
          {description}
        </Text>
      </Card>
    </>
  );
};
